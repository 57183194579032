.insights-button {
  & svg {
    fill: #018219 !important;
  }
  &:focus,
  &:hover {
    & svg {
      fill: #fff !important;
    }
  }
}

.search-icon-button {
  width: 35px !important;
  flex: 0 0 auto !important;
  border: 1px solid #ced4da !important;
  border-left: none !important;

  &:hover {
    & .material-symbols,
    .material-symbols-outlined {
      color: #fff !important;
    }
  }
}

.bg-insights {
  background-color: #0182191a !important;
  color: #014e0f !important;
}

.erm-logo-container {
  & svg {
    width: 2rem !important;
    height: 2rem !important;
  }
}

.insights-loader {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
  margin: 0px 28px;
  box-sizing: border-box;
  animation: animloader 1s linear infinite;
}

@keyframes animloader {
  0% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }
  25% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 2px;
  }
  50% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 2px, -38px 0 0 -2px;
  }
  75% {
    box-shadow: 14px 0 0 2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }
  100% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }
}
